.customQuill .ql-editor {
    overflow-y: scroll;
    resize: vertical;
}

.customQuill .ql-tooltip {
    left: 0 !important;
}

/** 
 * Found an issue post upgrade to react-quill 2.0.0 where this field double renders
 * seems to be no impact on functionality so hiding second
 * field for the moment. Can revisit later
 */

.customQuill .ql-tooltip > .mathquill4quill-mathquill-input:nth-of-type(n + 2) {
    display: none;
}
